@use "./colors";
@use "./spacings";
@use "./animations";

.sections {
  max-width: 100vw;
  color: colors.$white;
  padding: 1rem;
}

.landingButton {
  justify-self: center;
  align-self: center;
  opacity: 0;
  transform: translate(0, 5rem);
  transition: all ease-in-out calc(animations.$duration * 3);

  &.visible {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.homeSection {
  width: 100%;
  margin-top: -0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  position: relative;
  padding: 2rem;
  padding-bottom: 0rem;
  gap: 5rem;


  min-height: calc(100lvh - 3rem);
  .heroSectionCTA {
    padding-bottom: 2rem;
  }

  mark {
    background: transparent;
    color: colors.$main_brand_color;
    border-radius: 0.25rem;
  }

  .terminal {
    color: colors.$main_brand_color;
  }

  .heroSectionCTA {
    display: block;
    text-align: start;
    gap: 8rem;

    .heroLogo {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h1 {
      max-width: 30rem;
      text-align: left;
      font-weight: bold;
    }
    h2 {
      max-width: 30rem;
      text-align: left;
    }

    button {
      margin-top: 2rem;
    }
  }

  .heroImage {
    height: 100%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}





@media (prefers-reduced-motion) {
}

/* Mobile */
@media (max-width: 1200px) {
  .homeSection {
    gap: 0rem;
    .heroSectionCTA {
      gap: 0;
      align-items: start;
      &.developer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }

      .heroLogo {
        align-self: flex-end;
      }
    }
    .developer {
      display: block;
      position: relative;
      padding-top: 0rem;
      article {
        height: 100%;
        width: 100%;
        background: rgba($color: colors.$black, $alpha: 0.35);
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        p {
          max-width: 35ch;
        }
      }
      img {
        height: 100%;
        min-height: 100%;
        min-width: 100vw;
        object-fit: contain;
      }
    }
  }

  .howToSection {
    padding-right: 0;

    .howToContent {
      justify-content: center;
      .content_logo {
        width: 6rem;
        height: 6rem;
        margin-top: 12px;
      }
      h1,
      h2 {
        color: colors.$white;
        font-weight: bold;
        text-align: left;
        max-width: 30rem;
      }
    }

    .howToTrophies {
      align-items: center;
      .howToPaper {
        min-height: 5rem;
        height: 16rem;
        padding: 1rem;

        h2 {
          color: colors.$white;
        }
      }
    }
  }
}

/* Large Screens */
@media (min-width: 1500px) {
}

@media (prefers-color-scheme: dark) {
}

@media (hover: hover) and (pointer: fine) {
  .scrollDownButton:hover {
    transform: scale(3);
    animation-play-state: paused;
    transition: all ease-in-out 1s;
  }
}
