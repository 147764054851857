@use "@/styles/colors";
@use "@/styles/spacings";
@use "@/styles/animations";

.footer {
  background: colors.$main_brand_color;
  color: colors.$white;
  width: 100%;
  max-width: calc(100vw);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem;
  gap: 1rem;
  position: relative;

  h4,
  p {
    background: transparent;
  }
  a {
    color: colors.$white;
    font-weight: 400;

    text-decoration: none;
  }
  .footerSocial {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.5rem;
  }
}

.footerLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footerLinkList {
  align-items: center;
  justify-content: space-evenly;
  color: colors.$white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.footerLinkList li {
  list-style: none;
  cursor: pointer;
  color: colors.$white;
  transform: translateX(-2rem);
}

.socialLinks {
  height: 2rem;
  width: 2rem;
  color: colors.$white;
}

.socialLinks a {
  height: 100%;
  color: colors.$white;
}

@media (max-width: 1200px) {
  .footer {
    margin: auto;
    color: colors.$white;
    justify-content: center;
  }

  .footerLinkList li {
    transform: translateX(0rem);
  }
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .footerLinkList a:hover {
    color: colors.$white;
    opacity: 1;
    text-decoration: underline;
  }
}
